<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();

const props = defineProps<{
	game: Game;
	gameOfTheWeek?: boolean;
	customBadge?: string | boolean;
}>();

const customBadgeIsActive = computed(
	() => props.customBadge && !t(`categoryCustomBadge.${props.customBadge}`).includes("categoryCustomBadge")
);
</script>

<template>
	<div class="badges-row">
		<ABadge v-if="customBadgeIsActive" autosize background="var(--primary-400)">
			<AText class="custom" type="badge-sm">{{ t(`categoryCustomBadge.${customBadge}`) }}</AText>
		</ABadge>
		<ABadge v-if="gameOfTheWeek" autosize background="var(--gradient-10)">
			<AText type="badge-sm">{{ t("Game of the week") }}</AText>
		</ABadge>
		<ABadge v-if="game.isNew" autosize background="var(--gradient-5)">
			<AText type="badge-sm">{{ t("New") }}</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.badges-row {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	.custom {
		color: var(--neutral-950);
	}
}
</style>
